/**
 *  Dynamically changing favicons with JavaScript
 */

module.exports = function () {

    return function (app) {
        faviconChange()
    }
  
    function faviconChange() {
        jQuery('link[rel="shortcut icon"]').attr('id','dynamic-favicon');
        document.head || (document.head = document.getElementsByTagName('head')[0]);
        function changeFavicon(src) {
            var link = document.createElement('link'),
                oldLink = document.getElementById('dynamic-favicon');
            link.id = 'dynamic-favicon';
            link.rel = 'shortcut icon';
            link.href = src;
            if (oldLink) {
                document.head.removeChild(oldLink);
            }
            document.head.appendChild(link);
        }
        //changeFavicon('https://www.rechnerhaus.de/wp-content/themes/rechnerhaus/images/rechnerhaus_logopart_rhcolor'+ rhcolorSet +'.png');
    }
}
  