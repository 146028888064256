/**
 *   Load more items if filter not yet loaded enough
 */

module.exports = function () {

    return function (app) {
        inputToggle()
    }
  
    function inputToggle() {
        jQuery('input').on('ifToggled', function(event){
            // conditional checkbox trigger change for conditional fields to show
            jQuery(this).trigger("change", event);
            // show submit button on form acceptance
            if (jQuery(this).hasClass('formacceptance')) {
                jQuery('input.wpcf7-submit').attr('disabled', !event.target.checked);
            }
        });
    }
}
  