/**
 *  nav overlay
 */

module.exports = function () {

    return function (app) {
        navOverlay()
    }
  
    function navOverlay() {
        jQuery('.nav_menu').click(function(){
            jQuery(this).toggleClass("active");
            jQuery(".nav-content").toggleClass("open");
        });
    }
}
  