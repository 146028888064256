/**
 *  iCheck style checkbox and radio button with style square black
 */

module.exports = function () {

    return function (app) {
        iCheck()
    }
  
    function iCheck() {
        jQuery('input[type="checkbox"]:not(._brlbs-btn-switch input), input[type="radio"]').iCheck({
            checkboxClass: 'icheckbox_square',
            radioClass: 'iradio_square',
            increaseArea: '20%' // optional
        });

    }
}
  