/**
 *   Toggle button
 */

module.exports = function () {

    return function (app) {
        toggleButton()
    }
  
    function toggleButton() {
        jQuery('.togglenow').on('click', function (event) {
            event.preventDefault();
            jQuery('.section--toggle').slideDown();
            // Scroll down
            jQuery("html, body").animate({
                // scrollTop: jQuery(this).parent().parent().parent().parent().parent().parent().parent().parent().next().offset().top
                scrollTop: jQuery('.section--toggle').offset().top
            }, 800);
            return false;
        });

    }
}
  