/**
 *  Scroll to hash
 *
 */

module.exports = function () {

    return function (app) {
        scrollTohash()
    }
  
    /**
     * Initial Scroll to hash
     */
    function scrollTohash() {
        var jump = function(e) {
            var offset = 100;
            if (jQuery('body').hasClass('admin-bar')) {
                offset = 130;
            }
            if (e){
                // Stay on page if target element is here
                if (jQuery(target).length > 0) {
                    e.preventDefault();
                }
                var url = jQuery(this).attr("href");
                var target = url.substring(url.indexOf('#'));
            } else{
                var target = location.hash;
            }
    
            // Scroll to target
            if (jQuery(target).length > 0) {
                jQuery('html,body').animate({
                    scrollTop: jQuery(target).offset().top - offset
                }, 600);
                location.hash = target;
            }
        }
        // Bind click function on menu item
        jQuery(".m_icon_list a[href*='#']").bind('click', jump);
        
            // Scroll to hash on page load
        if (location.hash){
            setTimeout(function(){
                //jQuery('html, body').scrollTop(0);
                jump();
            }, 0);
        }

    }
}
  