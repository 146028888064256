'use strict'

/**
 * Module dependencies.
 */

// Vendor
var jQuery = window.$ = window.jQuery // = require('jquery')
// var Swiper = window.Swiper = require('swiper').default
var iCheck = require('./vendors/icheck')

// Libraries
// var mobileScroll = require('./lib/mobile-scroll')
var scrollTohash = require('./lib/scroll-to-hash')
var iCheckTheme = require('./lib/icheck-theme')
var faviconChange = require('./lib/favicon')
var toggleButton = require('./lib/toggle-button')
var scrollToRow = require('./lib/scroll-to-row')
var esgFilter = require('./lib/esg-filter')
var inputToggle = require('./lib/input-toggle')
var navOverlay = require('./lib/nav-overlay')


/**
 * Initialize a new App instannce.
 */

function App() {
  if (!(this instanceof App)) return new App()

  // init plugins

  // init actions
  $(document).ready(() => {
    this.use(navOverlay())
    // this.use(searchBar())
    // this.use(navToggle())
    // this.use(vcAccordion())
    // this.use(inlineSvg())
    // this.use(mobileScroll())
    this.use(scrollTohash())
    // this.use(flyout())
    this.use(iCheckTheme())
    this.use(faviconChange())
    this.use(toggleButton())
    this.use(scrollToRow())
    this.use(esgFilter())
    this.use(inputToggle())

  });
}

/**
 * Use the given plugin `fn`.
 *
 * @param {Function} fn
 * @return {App} self
 * @api public
 */

App.prototype.use = function (fn) {
  fn(this)
  return this
}

/**
 * Use easing speed and type accross the application.
 *
 * @return {Object}
 * @api public
 */
// App.prototype.anim = function () {
//   return {
//     speed: 150,
//     easing: easings.easeOutQuint
//   }
// }

// run the app
App()