/**
 *   Load more items if filter not yet loaded enough
 */

module.exports = function () {

    return function (app) {
        esgFilter()
    }
  
    function esgFilter() {
        if(jQuery('.esg-filterbutton').length > 0) {
            jQuery('.esg-filterbutton').click(function(){
                var filter = jQuery(this).attr('data-filter');
                //var count = jQuery(this).find('.eg-el-amount').text();
                var loadedItems = jQuery('.'+filter).length;
                //if (count > loadedItems) {
                    setTimeout(function(){
                        jQuery('.esg-loadmore').trigger( "click" );
                    }, 500);
                //}
            });
        }
    }
}
  