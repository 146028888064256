/**
 *  scroll to next row
 */

module.exports = function () {

    return function (app) {
        scrollToRow()
    }
  
    function scrollToRow() {
        jQuery('.rhvc_anchor_scroll .arrow').on('click', function () {
            jQuery("html, body").animate({
                scrollTop: jQuery(this).closest('.row').next().offset().top
            }, 800);
            return false;
        });
    }
}
  